import { css, createGlobalStyle } from 'styled-components';

// *** Theme Styling *** //
// --------------
// 1. Brand Colours
// --------------
// 2. Social Colours
// --------------
// 3. Notification Colours
// --------------
// 4. Padding Sizes
// --------------
// 5. Margin Sizes
// --------------
// 6. Fonts
// --------------
// 7. Breakpoints
// --------------
// 8. Cubic Bezier
// --------------
// 9. Magic Number
// --------------
// 10. Mixins
// --------------

export const gridBreakpoints = {
	small: 0,
	smedium: 370,
	medium: 700,
	large: 1024,
	xlarge: 1200,
	xxlarge: 1440,
	huge: 1600,
	uber: 2000,
};

// Theme Styles
export const themeStyles = {
	// 1. Social Colours
	// --------------
	facebook: '#3b5998',
	twitter: '#1da1f2',
	creativeMarket: '#8ba753',
	slack: '#e9a820',
	skype: '#00aff0',
	instagram: '#833ab4',
	dribbble: '#ea4c89',
	behance: '#1769ff',
	linkedin: '#0077b5',

	// 2. Notification Colours
	// --------------
	pos: '#3adb76', // Green (Success / Positive)
	neg: '#cc4b37', // Red (Error / Negative)
	warn: '#ffae00', // Yellow (Warning / Neutral)

	// 3. Brand Colours
	// --------------
	white: '#ffffff',
	black: '#000000',
	bc1: '#15B4EE', // Neon Blue
	bc2: '#8230E1', // Neon Purple
	bc3: '#150426', // Dark Purple
	bc4: '#1A0530', // Light Purple
	bc5: '#15B4EE20', // Neon Blue 20%
	bc6: '#8230E1', // Neon Purple 20%

	// 4. Padding Sizes
	// --------------
	mpad: '1.2rem', // Mobile Padding
	padS: '4.8rem', // Small
	padM: '8.4rem', // Medium
	padL: '12rem', // Large

	// 5. Margin Sizes
	// --------------
	marS: '4.8rem', // Small
	marM: '8.4rem', // Medium
	marL: '12rem', // Large

	// 6. Fonts
	// --------------
	heading: "'Recoleta', 'Helvetica', 'Arial', Serif;",
	mono: "'Inter', 'Helvetica', 'Arial', Monospace;",
	hand: "'Inter', 'Helvetica', 'Arial', Sans-Serif;",
	body: "'Inter', 'Helvetica', 'Arial', Sans-Serif;",

	light: 300,
	reg: 400,
	semi: 500,
	bold: 600,

	// 7. Grid
	// --------------
	small: gridBreakpoints.small + 'px',
	smedium: gridBreakpoints.smedium + 'px',
	medium: gridBreakpoints.medium + 'px',
	large: gridBreakpoints.large + 'px',
	xlarge: gridBreakpoints.xlarge + 'px',
	xxlarge: gridBreakpoints.xxlarge + 'px',
	huge: gridBreakpoints.huge + 'px',
	uber: gridBreakpoints.uber + 'px',

	gutterSmall: '24px',
	gutterLarge: '36px',
	maxGridSize: '1440px',

	// 8. Cubic Bezier
	// --------------
	bezzy: 'cubic-bezier(0.8, 0, 0.2, 1)',
	ease: 'all .3s ease-in-out',

	// 9. Magic Number
	// --------------
	magic: 1.2,

	// 10. Magic 100vh
	// --------------
	vh: `calc(var(--vh, 1vh) * 100)`,
	vhs: `calc(var(--vhs, 1vh) * 100)`,
};

export const darkTheme = {
	// 1. Brand Colours
	// --------------
	white: themeStyles.white,
	black: themeStyles.black,
	bc1: themeStyles.bc1,
	bc2: themeStyles.bc2,
	bc3: themeStyles.bc3,
	bc4: themeStyles.bc4,

	// 2. Social Colours
	// --------------
	facebook: themeStyles.facebook,
	twitter: themeStyles.twitter,
	creativeMarket: themeStyles.creativeMarket,
	slack: themeStyles.slack,
	skype: themeStyles.skype,
	instagram: themeStyles.instagram,
	dribbble: themeStyles.dribbble,
	behance: themeStyles.behance,
	linkedin: themeStyles.linkedin,

	// 3. Notification Colours
	// --------------
	pos: themeStyles.pos, // Green (Success / Positive)
	neg: themeStyles.neg, // Red (Error / Negative)
	warn: themeStyles.warn, // Yellow (Warning / Neutral)

	// 4. Padding Sizes
	// --------------
	mpad: themeStyles.mpad, // Mobile Padding
	padS: themeStyles.padS, // Small
	padM: themeStyles.padM, // Medium
	padL: themeStyles.padL, // Large

	// 5. Margin Sizes
	// --------------
	marS: themeStyles.marS, // Small
	marM: themeStyles.marM, // Medium
	marL: themeStyles.marL, // Large

	// 6. Fonts
	// --------------
	heading: themeStyles.heading,
	mono: themeStyles.mono,
	hand: themeStyles.hand,
	body: themeStyles.body,
	light: themeStyles.light,
	reg: themeStyles.reg,
	semi: themeStyles.semi,
	bold: themeStyles.bold,

	// 7. Grid
	// --------------
	small: themeStyles.small,
	smedium: themeStyles.smedium,
	medium: themeStyles.medium,
	large: themeStyles.large,
	xlarge: themeStyles.xlarge,
	xxlarge: themeStyles.xxlarge,
	huge: themeStyles.huge,
	uber: themeStyles.uber,

	gutterSmall: themeStyles.gutterSmall,
	gutterLarge: themeStyles.gutterLarge,
	maxGridSize: themeStyles.maxGridSize,

	// 8. Cubic Bezier
	// --------------
	bezzy: themeStyles.bezzy,
	ease: themeStyles.ease,

	// 9. Magic Number
	// --------------
	magic: themeStyles.magic,

	// 10. Magic 100VH
	// --------------
	vh: css`
		height: 100vh;
		height: ${themeStyles.vh};
	`,

	vhs: css`
		height: 100vh;
		height: ${themeStyles.vhs};
	`,

	// No Scrollbars
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			scrollbar-width: none;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			width: 0 !important;
			height: 0 !important;
			display: none;
		}
	`,

	// Vertical Align
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	`,

	// Vertical Align Cancel
	valignCancel: css`
		position: relative;
		top: auto;
		transform: none;
		width: auto;
	`,

	// Total Align
	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	// Total Align Cancel
	talignCancel: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,

	// Magic Cubic Bezier Animation
	cubic: (time, bezier) => css`
		transition: all ${time} ${bezier};
	`,

	// Simplified Animation
	animate: (time, ease) => css`
		transition: all ${time} ${ease};
	`,

	// Simplified Animation Delay
	delay: (time) => css`
		transition-delay: ${time};
	`,

	// Icon Sizer
	sizer: (size) => css`
		width: ${size};
		height: ${size};
	`,

	// Gradient
	grad: (direction, color1, color2) => css`
		background: linear-gradient(${direction}deg, ${color1} 0%, ${color2} 100%);
	`,
};

export const lightTheme = {
	// 1. Brand Colours
	// --------------
	white: themeStyles.white,
	black: themeStyles.black,
	bc1: themeStyles.bc1, // Light Black
	bc2: themeStyles.bc2, // Light Grey
	bc3: themeStyles.bc3, // Dark Blue
	bc4: themeStyles.bc4, // Light Blue

	// 2. Social Colours
	// --------------
	facebook: themeStyles.facebook,
	twitter: themeStyles.twitter,
	creativeMarket: themeStyles.creativeMarket,
	slack: themeStyles.slack,
	skype: themeStyles.skype,
	instagram: themeStyles.instagram,
	dribbble: themeStyles.dribbble,
	behance: themeStyles.behance,
	linkedin: themeStyles.linkedin,

	// 3. Notification Colours
	// --------------
	pos: themeStyles.pos, // Green (Success / Positive)
	neg: themeStyles.neg, // Red (Error / Negative)
	warn: themeStyles.warn, // Yellow (Warning / Neutral)

	// 4. Padding Sizes
	// --------------
	mpad: themeStyles.mpad, // Mobile Padding
	padS: themeStyles.padS, // Small
	padM: themeStyles.padM, // Medium
	padL: themeStyles.padL, // Large

	// 5. Margin Sizes
	// --------------
	marS: themeStyles.marS, // Small
	marM: themeStyles.marM, // Medium
	marL: themeStyles.marL, // Large

	// 6. Fonts
	// --------------
	heading: themeStyles.heading,
	mono: themeStyles.mono,
	hand: themeStyles.hand,
	body: themeStyles.body,
	light: themeStyles.light,
	reg: themeStyles.reg,
	semi: themeStyles.semi,
	bold: themeStyles.bold,

	// 7. Grid
	// --------------
	small: themeStyles.small,
	smedium: themeStyles.smedium,
	medium: themeStyles.medium,
	large: themeStyles.large,
	xlarge: themeStyles.xlarge,
	xxlarge: themeStyles.xxlarge,
	huge: themeStyles.huge,
	uber: themeStyles.uber,

	gutterSmall: themeStyles.gutterSmall,
	gutterLarge: themeStyles.gutterLarge,
	maxGridSize: themeStyles.maxGridSize,

	// 8. Cubic Bezier
	// --------------
	bezzy: themeStyles.bezzy,
	ease: themeStyles.ease,

	// 9. Magic Number
	// --------------
	magic: themeStyles.magic,

	// 10. Magic 100VH
	// --------------
	vh: css`
		height: 100vh;
		height: ${themeStyles.vh};
	`,

	vhs: css`
		height: 100vh;
		height: ${themeStyles.vhs};
	`,

	// No Scrollbars
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			scrollbar-width: none;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			width: 0 !important;
			height: 0 !important;
			display: none;
		}
	`,

	// Vertical Align
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	`,

	// Vertical Align Cancel
	valignCancel: css`
		position: relative;
		top: auto;
		transform: none;
		width: auto;
	`,

	// Total Align
	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	// Total Align Cancel
	talignCancel: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,

	// Magic Cubic Bezier Animation
	cubic: (time, bezier) => css`
		transition: all ${time} ${bezier};
	`,

	// Simplified Animation
	animate: (time, ease) => css`
		transition: all ${time} ${ease};
	`,

	// Simplified Animation Delay
	delay: (time) => css`
		transition-delay: ${time};
	`,

	// Icon Sizer
	sizer: (size) => css`
		width: ${size};
		height: ${size};
	`,

	// Gradient
	grad: (direction, color1, color2) => css`
		background: linear-gradient(${direction}deg, ${color1} 0%, ${color2} 100%);
	`,
};

export const GlobalStyle = createGlobalStyle(
	(props) => css`
		html,
		body {
			${props.theme.noscrollbars}
			background-color: ${props.theme.bc4};
			color: ${props.theme.white};
		}
	`
);
