// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = (props) => css`
	font-weight: ${props.isBold ? props.theme.semi : props.theme.reg};
	font-family: ${props.theme.heading};
	line-height: 1.2;
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
	${sharedHeadingStyles}
	font-size: 4.8rem;

	${breakpointUp.medium` font-size: 8.4rem; `}
	${breakpointUp.large` font-size: 10.8rem; `}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 4.4rem;

	${breakpointUp.medium` font-size: 7.2rem; `}
	${breakpointUp.large` font-size: 8.4rem; `}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 4rem;

	${breakpointUp.medium` font-size: 4.8rem; `}
	${breakpointUp.large` font-size: 6rem; `}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 3.4rem;

	${breakpointUp.medium` font-size: 4rem; `}
	${breakpointUp.large` font-size: 4.8rem; `}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 2.6rem;

	${breakpointUp.medium` font-size: 3rem; `}
	${breakpointUp.large` font-size: 3.6rem; `}
`;

export const h6Styles = css`
	${sharedHeadingStyles}
	font-size: 1.6rem;

	${breakpointUp.medium` font-size: 1.8rem; `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = (props) => css`
	font-family: ${props.theme.body};
	font-weight: ${props.isBold ? props.theme.med : props.theme.reg};
	line-height: 1.6;
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
	font-size: 1.6rem;

	${breakpointUp.medium` font-size: 1.8rem; `}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
	font-size: 1.4rem;

	${breakpointUp.large` font-size: 2.8rem; `}
`;

export const emStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	font-style: normal;
	font-weight: ${props.theme.semi};
	font-size: 1rem;
	line-height: 160%;
	text-transform: uppercase;
	letter-spacing: 0.12em;
`;
