// Imports
// ------
import React from 'react';
import { themeStyles } from '@theme';
import { observer } from 'mobx-react-lite';
import { AnimatePresence } from 'framer-motion';
import { loaderState } from '@states/loader';

// Styles
// ------
import { Jacket, Logo, Path } from './styles';

// Component
// ------
function Loader() {
	// NOTE • Animation
	const animation = {
		initial: {
			opacity: 1,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
			filter: `blur(2rem)`,
			scale: 1.5,
			transition: { duration: 1 },
		},
	};

	return (
		<AnimatePresence>
			{loaderState.isNeeded && (
				<Jacket {...animation}>
					<Logo
						xmlns='http://www.w3.org/2000/svg'
						width='65.68'
						height='65.68'
						viewBox='0 0 65.68 65.68'>
						<defs>
							<linearGradient
								id='loader-grad'
								y1='32.84'
								x2='65.68'
								y2='32.84'
								gradientUnits='userSpaceOnUse'>
								<stop offset='0' stopColor={themeStyles.bc1} />
								<stop offset='1' stopColor={themeStyles.bc2} />
							</linearGradient>
						</defs>
						<Path d='M42.84,30.9v7.18a7.62,7.62,0,0,1-7.62,7.61h0a7.62,7.62,0,0,1-7.62-7.61h0A7.62,7.62,0,0,1,20,30.46h0a7.62,7.62,0,0,1,7.62-7.62H42.42A20.27,20.27,0,0,1,62.83,42.28a20,20,0,0,1-20,20.56H6.65A3.81,3.81,0,0,1,2.84,59V23.25c0-10.89,8.54-20.11,19.44-20.4a20,20,0,0,1,20.56,20' />
					</Logo>
				</Jacket>
			)}
		</AnimatePresence>
	);
}

export default observer(Loader);
