// Imports
// ------
import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Circle } from './styles';

// Component
// ------
function Background(props) {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	return (
		<Jacket>
			<Circle>
				<circle
					cx={bp.medium ? 420 : 150}
					cy={bp.medium ? 420 : 150}
					r={bp.medium ? 420 : 150}
				/>
			</Circle>

			<Circle blue>
				<circle
					cx={bp.medium ? 420 : 150}
					cy={bp.medium ? 420 : 150}
					r={bp.medium ? 420 : 150}
				/>
			</Circle>

			{props.children}
		</Jacket>
	);
}

export default Background;
