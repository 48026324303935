// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup } from '@tackl';
import { motion } from 'framer-motion';

// Exports
// ------
export const Jacket = styled(motion.div)(
	(props) => css`
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background: ${props.theme.bc4};
		pointer-events: none;
	`
);

export const Logo = styled.svg(
	(props) => css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 8.4rem;
		height: 8.4rem;
	`
);

const stroke = keyframes`
	0% {
		stroke-dashoffset: 275;
	}
	50% {
		stroke-dashoffset: 550;
	}
	100% {
		stroke-dashoffset: 825;
	}
`;

export const Path = styled.path(
	(props) => css`
		fill: none;
		stroke-width: 6px;
		stroke: url('#loader-grad');

		stroke-dasharray: 275 275;
		stroke-dashoffset: 275;
		stroke-linecap: butt;

		animation: ${stroke} 2000ms ease-in-out infinite;
		will-change: stroke-dashoffset;
	`
);
