module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"4f6029fe0e90abc924a8170988478133","enableOnDevMode":true,"pageViews":"all","trackPageViewsAs":"Page View"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(min-width: 320px)","smedium":"(min-width: 375px)","medium":"(min-width: 700px)","large":"(min-width: 1024px)","xlarge":"(min-width: 1200px)","xxlarge":"(min-width: 1400px)","huge":"(min-width: 1600px)","uber":"(min-width: 18000px)","portrait":"(orientation: portrait)","horizontal":"(orientation: horizontal)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balbycare","short_name":"BalbyCare","start_url":"/","background_color":"#1A0530","theme_color":"#1A0530","display":"fullscreen","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58ff721a00a380e5a53f15c0973b9213"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
