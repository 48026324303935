// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		//-
	`
);

const circleAni = (props) => keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

export const Circle = styled.svg(
	(props) => css`
		position: fixed;
		z-index: 0;
		top: ${props.blue ? `100%` : `0%`};
		left: ${props.blue ? `100%` : `0%`};
		transform: translate(-50%, -50%);
		width: 300px;
		height: 300px;
		overflow: visible;

		${breakup.medium`
			width: 840px;
			height: 840px;
		`}

		circle {
			stroke: ${props.blue ? props.theme.bc1 : props.theme.bc2};
			stroke-width: 3.6rem;
			fill: transparent;
			stroke-linecap: round;
			transform: rotate(0deg);
			transform-origin: center center;

			stroke-dasharray: 340;
			stroke-dashoffset: 0;

			animation: ${circleAni} 2s linear infinite both;
			animation-delay: ${props.blue ? `0.5s` : `0s`};

			${breakup.medium`
				stroke-width: 8.4rem;
				stroke-dasharray: 120rem;
				stroke-dashoffset: 120rem;
				animation: ${circleAni} 4s linear infinite both;
			`}
		}
	`
);
