// Imports
// ------
import React, { useEffect } from 'react';
import Loader from '@parts/Loader';
import Background from '@parts/Background';
// import SmoothScroll from '@parts/SmoothScroll';
import loadable from '@loadable/component';
import { loaderState } from '@states/loader';
import { runInAction } from 'mobx';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Styles
// ------
import '@css/critical.css';
import '@css/non-critical.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme, GlobalStyle } from '@theme';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Lazy
// ------
const Header = loadable(() => import('@parts/Header'));
const Menu = loadable(() => import('@parts/Menu'));
const FacebookChat = loadable(() => import('@parts/FacebookChat'));
const Cursor = loadable(() => import('@parts/Cursor'));

// Component
// ------
function Layout({ children, location, content }) {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • MIXPANEL
	const mixpanel = useMixpanel();

	// NOTE • OnLoad: 100vh Fix
	useEffect(() => {
		// Crate function
		const setHeightVarStatic = () => {
			let vh = document.documentElement.clientHeight * 0.01;
			document.documentElement.style.setProperty('--vhs', `${vh}px`);
		};

		const setHeightVar = () => {
			let vh = document.documentElement.clientHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};

		// Init
		setHeightVar();
		setHeightVarStatic();

		// Onresize rurun function
		window.addEventListener('resize', setHeightVar);

		return () => {
			window.removeEventListener('resize', setHeightVar);
		};
	}, []);

	// NOTE • OnLoad: Scrolling Performance
	useEffect(() => {
		let body = document.body;
		let timer;

		const hoverScroll = () => {
			clearTimeout(timer);

			if (!body.classList.contains('disable-hover')) {
				body.classList.add('disable-hover');
			}

			timer = setTimeout(function () {
				body.classList.remove('disable-hover');
			}, 150);
		};

		window.addEventListener('scroll', hoverScroll, false);

		return () => {
			window.removeEventListener('scroll', hoverScroll, false);
		};
	}, []);

	// NOTE • OnLoad: Loader Animation
	useEffect(() => {
		const loader = () => {
			loaderState.isNeeded = false;
		};

		const timer = setTimeout(() => {
			runInAction(loader);
		}, 2000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • Mixpanel
	useEffect(() => {
		mixpanel.track(`Page: ${location.pathname}`);
	}, [location]);

	return (
		<>
			<ThemeProvider theme={lightTheme}>
				<Loader />
			</ThemeProvider>

			<GlobalStyle theme={lightTheme} />

			<ThemeProvider theme={lightTheme}>
				<Header />
				<Menu />
				<FacebookChat data={content} />

				{bp.large && <Cursor location={location} />}

				<Background>{children}</Background>
			</ThemeProvider>
		</>
	);
}

export default Layout;
